.form {
  &__help-text {
    margin-bottom: $global-margin;
    position: relative;
    top: -1 * $global-margin;
  }
}

label {
  font-size: rem-calc(14);
  font-weight: 700;

  &.navigation-label {
    font-weight: 400;
  }
}

.input-group {
  margin-bottom: 0;
}

.wrappable-input-group {
  display: flex;
  grid-gap: rem-calc(26);

  .wrappable-input {
    padding: 13px;
    width: auto;
    flex: 1;
  }
}

.wrappable-button-group {
  display: flex;
  grid-gap: rem-calc(26);

  .wrappable-button {
    padding: 13px;
    width: auto;
    flex: 1;
  }
}
