.header-wrapper {
  position: sticky;
  top: 0;

  // A very large value so the header is always on top
  z-index: 10000;
}

.header {
  background-color: $white;
  border-bottom: 3px solid $light-gray;
  display: flex;
  flex-direction: row;
  height: rem-calc(53);
  justify-content: space-between;
  width: 100%;

  .header__button {
    height: 100%;
  }

  .logo {
    height: rem-calc(50);
    justify-content: center;
    padding: 0 1rem;
    width: rem-calc(108);

    @include breakpoint(large) {
      width: rem-calc(200);
    }
  }
}
.tablet-header{
  display: flex;
  &__item{
    width: calc(25%);
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px white solid;
    background-color: get-color(tertiary);
    height: 50px;
    &--logo{
    height: rem-calc(50);
    padding: 0 1rem;
    }
    &:nth-child(2), &:nth-child(3) {
      background-color:  $primary-color;
    }
    &--state-selected{
      &:nth-child(2), &:nth-child(3) {
        background-color: $selected-primary ;
      }
    }
    &:last-child{
      border: none;
    }
  }
  &__network-status-indicator{
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    &--online{
     background-color: #1E824C;
    }
    &--offline{
     background-color: #D51622;
    }
  }
  span {
    font-weight: bold;
    margin-left: .5rem;
  }
}
.tablet-drawer{
  width: 100%;
  height: 250px;
  background-color: get-color(tertiary);
  border-top: 2px $white solid;
  z-index: 1000;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-end;
  &__list{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  &__link{
    background-color: $white;
    height: 135px;
    width: 200px;
    border-radius: 5px;
    align-items: center;
    color: $black;
  }
  &__close{
    color: $white ;
    height: 40px;
    display: flex;
    align-items: center;
    width: 85px;
    padding-bottom: 20px;
  }
  &__chevron{
    margin-right: 10px;
  }
  &__image{
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.1rem;
}
&__text{
    h2 {
      margin-bottom: 0;
    }
    h4 {
      margin-bottom: 0.1rem;
    }
  }
}
.overlay{
  background-color: #999999;
  height: 100vh;
  left: 0;
  opacity: 0.5;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 500;
}
