.sidebar {
  background: get-color(tertiary);
  color: $white;
  height: 100%;
  overflow: auto;
  position: fixed;
  top: 0;
  padding-top: 3rem;
  width: rem-calc(108);

  @include breakpoint(large) {
    width: rem-calc(200);
  }

  .sidebar__button {
    background: transparent;
    border-bottom: 1px solid rgba(get-color(secondary), 0.12);
    color: $white;
    height: rem-calc(47);
    margin-bottom: 0;
    width: 100%;

    &:first-of-type {
      margin-top: rem-calc(11);
    }

    &.is-active  {
      box-shadow: inset 0 0 rem-calc(7) rem-calc(1) rgba(#111, 0.42);
    }
  }
}
