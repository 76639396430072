dl {
  &.data-list {
    div {
      &.row {
        margin: 10px 0;
      }
    }

    dt {
      clear: left;
      float: left;
    }

    dd {
      font-size: 14px;
    }
  }
}
