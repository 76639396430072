.alert {
  &__hollow {
    @include callout-base;
    border: 1.5px solid $warning-color;
    padding: 1rem;

    .link-button {
      color: get-color(primary);
      cursor: pointer;
      text-decoration: underline;
    }
  }

  @include callout-base;
  @include callout-style($light-gray);
  border: 1.5px solid $warning-color;
  padding: 0 1rem;

  &__buttons {
    button {

      margin: 0 !important;

    }
  }

  &__icon {
    padding-right: 10px;
  }

  &__message {
    font-weight: bold;
  }
}
