/* adapted from https://www.w3schools.com/howto/howto_css_custom_checkbox.asp */
.checkbox-label {
  cursor: pointer;
  display: block;
  margin-bottom: rem-calc(12);
  padding-left: rem-calc(35);
  position: relative;
  user-select: none;
  max-width: 100%;

  /* Hide the browser's default checkbox */
  input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;

    /* Show the checkmark when checked */
    &:checked ~ .checkmark {
      &::after {
        display: block;
      }
    }
  }

  /* On mouse-over, add a black border */
  :hover {
    input ~ .checkmark {
      border: 1px solid $black;
    }
  }


  /* Create a custom checkbox */
  .checkmark {
    background-color: $white;
    border: 1px solid $medium-gray;
    height: rem-calc(20);
    left: 0;
    position: absolute;
    top: rem-calc(2);
    width: rem-calc(20);

    /* Create the checkmark (hidden when not checked) */
    &::after {
      border: solid $black;
      border-width: 0 3px 3px 0;
      content: "";
      display: none;
      height: rem-calc(16);
      left: rem-calc(6);
      position: absolute;
      transform: rotate(45deg);
      width: rem-calc(8);
    }
  }
}
