.dropdown-pane {

  .menu {
    a {
      &,
      &:hover,
      &:active {
        color: $white;
      }
    }
  }
}
