.dashboard {
  margin-left: 1rem;
  margin-top: 1rem;

  h1 {
    margin-top: 1rem;
    color: #666;
    font-weight: $global-weight-normal;
  }
}

.dashboard-panel {
  border: 1px solid #ddd;
  border-radius: rem-calc(5);
  height: min-content;
  margin-left: 0;
  margin-top: rem-calc(10);
}

.dashboard-panel__header {
  background-color: #fafafa;
  padding: 1rem 1.25rem 1rem 2rem;

  h2 {
    margin-bottom: 0;
  }
}

.dashboard-panel__body {
  border-top: 1px solid #ddd;
  background-color: $white;
  padding: 1rem 2rem;
}

.dashboard-link {
  a {
    color: $black;
    font-family: $body-font-family;
    font-size: rem-calc(12);
  }
}
