.pagination {
  @include pagination-container;
  @include pagination-item-current;
  @include pagination-item-disabled;
  @include pagination-ellipsis;
  background-color: $white;
  border: 0.5px solid $form-medium-gray;
  border-radius: rem-calc(4);
  cursor: default;
  font-size: 0;
  padding: 0 0.625rem;
  width: fit-content;
  display: inline-block;

  li {
    border-right: 0.5px solid $form-medium-gray;
    margin: 0;

    button {
      cursor: pointer;
      font-size: 14px;
      text-decoration: none;

      &:disabled {
        cursor: not-allowed;
      }
    }

    &:last-child {
      border-right: 0;
    }
  }
}
