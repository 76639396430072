.button {
  align-items: center;
  color: $white;
  display: inline-flex;

  .button-group--spaced & {
    margin: 0 5%;
  }

  & > .icon {
    margin-right: 0.45rem;
  }

  &.icon-only > .icon {
    margin-right: 0;
  }

  &.clear {
    color: $black;
    background: transparent;
  }

  &.primary {
    background: get-color(primary);

    &:active,
    &:hover,
    &:focus {
      background: darken(get-color(primary), 20%);
    }
  }

  &.secondary {
    background: get-color(secondary);
    color: $black;

    &:active,
    &:hover,
    &:focus {
      background: darken(get-color(secondary), 20%);
    }
  }

  &.tertiary {
    background: get-color(tertiary);

    &:active,
    &:hover,
    &:focus {
      background: rgba(get-color(tertiary), 0.8);
    }
  }

  &.padded {
    padding: 1rem;
    font-weight: 700;
  }
}

.button-group {
  flex: wrap;

  &--spaced {
    justify-content: center;
    margin: $global-margin 0 2 * $global-margin;
  }

  &--reversed {
    flex-direction: row-reverse;
  }
}
