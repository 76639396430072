.palette {
  font-size: rem-calc(12);
  height: rem-calc(100);
  margin: rem-calc(10);
  padding: rem-calc(5);
  width: rem-calc(100);

  &.yellow {
    background-color: $yellow;
  }

  &.primary {
    background-color: $primary-color;
    color: $white;
  }

  &.secondary {
    background-color: $secondary-color;
  }

  &.tertiary {
    background-color: get-color(tertiary);
    color: $white;
  }

  &.light-grey {
    background-color: $light-gray;
  }

  &.dark-grey {
    background-color: $dark-gray;
    color: $white;
  }

  &.success {
    background-color: $success-color;
    color: $white;
  }

  &.warning {
    background-color: $warning-color;
  }

  &.alert {
    background-color: $alert-color;
    color: $white;
  }
}

.styleguide__colours {
  p {
    margin-bottom: 0;
  }
}

.styleguide__buttons {
  p {
    font-size: rem-calc(10);
    margin-right: 10px;
  }
}
