.panel {
  @include callout-base;
  @include callout-style($white);
  font-size: 14px;

  &.secondary {
    background: get-color(secondary);
  }

  &.light-gray {
    background: $light-gray;
  }

  &.summary {
    background: $light-gray;
    border: 0;
    border-radius: 5px;
    border-top: 2px solid #eee;
    padding: 1.25rem 2rem 2rem;

    a {
      font-size: 14px;
    }

    p {
      font-size: 14px;
    }

    .panel-label {
      font-size: rem-calc(16);
      font-weight: 600;
      line-height: rem-calc(21);
      margin-bottom: rem-calc(12);
      margin-top: rem-calc(30);
      color: #333333;
    }

    .panel-label + select {
      margin-bottom: 0;
    }

    &.with-header {
      padding: 0;
      .panelheader {
        border-bottom: 1px solid #eee;
        padding: 1rem 2rem;
      }

      .panel-inner {
        padding: 0 2rem 2rem;
      }
    }
  }

  &--bordered {
    border: 1px solid $medium-dark-gray;
    border-radius: rem-calc(5px);
  }

  &--compact {
    padding: 1rem 0.75rem;
    margin-bottom: 0;
  }

  // Padding designed to present a series of panels stacked vertically
  &--list {
    padding: 0.75rem 1.5rem;
  }

  &--large {
    margin: rem-calc(40) rem-calc(32);
  }

  &.devise {
    border: 0;
    border-radius: 6px;
    margin: 5rem;

    h3 {
      font-size: 24px;
    }

    .welcome {
      margin-top: 2rem;
    }

    &--form {
      border-color: #e3e5ec;
      border-radius: 5px 5px 0 0;
      border-style: solid;
      border-width: 1px 0;
      margin: 2rem 4rem;
      padding: 1rem;

      .field {
        margin: 0 3rem;
      }

      .forgot-password {
        text-align: right;
      }

      .submit-button {
        text-align: center;
      }
    }
  }
}
