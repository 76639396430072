.mentions-list--header {
  background-color: $white;
  border-radius: $global-radius;
  font-size: 20px;
  margin: 1rem 0 0 0;
  padding: 1rem;
}

.mention {
  background-color: $white;
  border-top: 1px solid $medium-gray;
  font-size: 14px;
  padding: 1rem;

  .title {
    color: $black;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
  }

  .link {
    font-size: 14px;
    font-weight: bold;
    color: $primary-color;
  }

  .icon {
    margin: auto;
    width: 50%;
  }

  p {
    color: $black;
    font-size: 14px;
    margin: 0;
    padding: 0;
  }

  .date {
    color: #757575;
  }

  .mention-buttons {
    button {
      color: $primary-color;
      font-size: 12px;
      margin-top: 0.5rem;
    }
  }
}

.unread-mention {
  // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
  @extend .mention;

  box-shadow: -4px 0 $primary-color;
}
