.label {
  line-height: 1.25rem;
  font-weight: bold;
  margin-right: 0.5rem;

  &.success {
    background: $white;
    color: get-color(success);
  }

  &.warning {
    background: white;
    color: get-color(tertiary);
    border: solid 3px get-color(warning);
  }

  &.secondary {
    background: get-color(secondary);
    color: $dark-gray;
  }
}
