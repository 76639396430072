body,
html {
  min-height: 100vh;
  position: relative;
}

body {
  display: flex;
  flex-direction: column;
}

header {
  flex-shrink: 1;
}

header + .row {
  flex: 1;
}

main {
  @include xy-grid-container;
  flex: 1 0 auto;
  width: 100%;

}

.desktop-sidebar + main {
  margin: 0;

  padding-left: rem-calc(108 + (map-get($grid-column-gutter, medium) / 2)) !important;

  @include breakpoint(large) {
    padding-left: rem-calc(200 + (map-get($grid-column-gutter, medium) / 2)) !important;

  }
}

footer {
  flex-shrink: 1;
}

.row {
  @include xy-grid;
  clear: both;

  &--spaced {
    margin-bottom: $global-margin;

    &:first-of-type {
      margin-top: $global-margin;
    }
  }

  &--divider {
    @include xy-gutters($grid-margin-gutters, padding);
    border-bottom: solid $medium-gray 1px;
    padding-bottom: $global-padding;
  }
}

.block {
  @include xy-cell;

  &--9 {
    @include breakpoint(medium) {
      @include xy-cell(9);
    }
  }

  &--8 {
    @include breakpoint(medium) {
      @include xy-cell(8);
    }
  }

  &--6 {
    @include breakpoint(medium) {
      @include xy-cell(6);
    }
  }

  &--4 {
    @include breakpoint(medium) {
      @include xy-cell(4);
    }
  }

  &--3 {
    @include breakpoint(medium) {
      @include xy-cell(3);
    }
  }
}


// Vertically divides cells in row.
// Not intended to, but can, contain content.
// Usage: .cell.shrink.divider-cell
.divider-cell {
  height: 100%; // Fill the row
  display: flex;
  align-items: center; // Middle-align items
  border-right: solid 1px $white;
  padding-right: 1rem;
  margin-right: 1rem;
}
