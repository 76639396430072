table {
  .checkbox-label {
    .checkmark {
      border: 1px solid #ddd;
      height: rem-calc(17);
      left: rem-calc(10);
      top: rem-calc(-2);
      width: rem-calc(17);

      &::after {
        height: rem-calc(14);
        left: rem-calc(5);
        width: rem-calc(6);
      }
    }
  }

  .table-header {
    background: get-color(tertiary);
    border-right: 1px solid $white;
    color: $white;
    font-size: rem-calc(11);
    font-weight: 700;
    min-width: rem-calc(150);
    text-align: center;
    text-transform: uppercase;

    &.table-header__checkbox {
      min-width: (rem-calc(45));
    }

    &.table-header__dashboard {
      min-width: min-content;
    }

    div[role=button] {
      cursor: pointer;
    }

    &--small {
      min-width: rem-calc(90);
    }

    &--medium {
      min-width: rem-calc(120);
    }
  }

  .table-text {
    border-right: 1px solid #ddd;
    font-size: rem-calc(12);
    word-break: break-word;
  }

  .active-row {
    box-shadow: inset 5px 0 get-color(primary)
  }
}
