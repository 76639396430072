.collapsible-list {
  &__control {
    @include button;
    @include button-clear;
    @include button-clear-style($dark-gray, 0%);
    font-weight: bold;
    font-size: 0.75rem;
    margin-bottom: 0;

    &::after {
      background-color: $dark-gray;
      background-size: 1rem;
      background-repeat: no-repeat;
      content: " ";
      display: inline-block;
      height: 1rem;
      mask-image: url("images/chevron-down.svg");
      margin-left: 0.75rem;
      transition: transform 0.2s linear;
      width: 1rem;
      vertical-align: inherit;
    }

    &--nested {
      &::after {
        background-size: 0.75rem;
        height: 0.75rem;
        width: 0.75rem;
      }
    }

    &--collapse {
      &::after {
        transform: rotate(180deg);
      }
    }
  }
}
