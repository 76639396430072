[data-toggle] {
  &.toggler--indicated {
    &::after {
      background-image: url("images/chevron-down.svg");
      background-repeat: no-repeat;
      background-size: 16px;
      content: " ";
      display: inline-block;
      height: 16px;
      margin-left: 0.5rem;
      transition: transform 0.2s linear;
      width: 16px;
    }

    &[aria-expanded="true"] {
      &::after {
        transform: rotate(-180deg);
      }
    }
  }
}

.initially-hidden {
  display: none;

  &.is-visible {
    display: block;
  }
}
