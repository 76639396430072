@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");

h1 {
  color: get-color(tertiary);
  font-weight: 700;
}

h2 {
  font-weight: 700;
}

h4 {
  font-weight: 700;
}

h5 {
  font-weight: 700;
  text-transform: uppercase;
}

p {
  font-size: rem-calc(16);
}

a {
  font-size: rem-calc(16);
}

// Generate Bootstrap-style text colour utilities
@each $name, $value in $foundation-palette {
  .text-#{$name} {
    color: $value;
  }
}
