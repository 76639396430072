.wizard-progress {
  @include menu-base;
  @include menu-expand;

  counter-reset: wizard-progress-counter;
  font-size: 0.9rem;
  margin-bottom: $global-margin;
  text-align: center;

  li {
    border-bottom: $callout-border;
    border-bottom-width: 4px;
    padding: $global-menu-padding;

    &::before {
      content: counter(wizard-progress-counter) ". ";
      counter-increment: wizard-progress-counter;
    }

    &.is-active {
      border-bottom-color: $black;
    }
  }
}
