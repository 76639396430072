.log-entry {
  &__user {
    color: $medium-dark-gray;
    font-size: rem-calc(11);
    line-height: rem-calc(15);
  }

  &__button {
    align-items: center;
    display: flex;
    font-size: rem-calc(13);
    line-height: rem-calc(18);
    margin-left: 2rem;

    img {
      height: 1rem;
      margin-right: 0.5rem;
    }

    &--primary {
      color: get-color(primary);
    }
  }
  &__body {
    background-color: rgba(227, 229, 236, 0.5);
    margin-top: 0.25rem;
    margin-bottom: 1rem;
    padding: 0.5rem;
  }
}

.mentioned {
  color: get-color(primary);
  font-weight: bold;
}

.mention-area {
  background-color: $white;
  font-weight: normal;
  min-height: rem-calc(109);

  .inline-mention {
    background-color: $white;
    color: get-color(primary) !important;
    position: relative;
    z-index: 10;
  }

  &__control {
    min-height: rem-calc(109);
    margin-bottom: rem-calc(16);
  }
  &__highlighter {
    line-height: rem-calc(21);
    padding: rem-calc(9);
  }
  &__input {
    padding: rem-calc(9);
  }

  &__suggestions {
    &__list {
      background-color: $white;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
    }

    &__item {
      min-width: rem-calc(257);
      padding: rem-calc(8) rem-calc(16);

      &--focused {
        background-color: get-color(primary);
        color: $white;
      }
    }
  }
}
