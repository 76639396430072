/* adapted from https://www.w3schools.com/howto/howto_css_custom_checkbox.asp */

.radio-label {
  cursor: pointer;
  display: block;
  margin-bottom: rem-calc(12);
  padding-left: rem-calc(35);
  position: relative;
  user-select: none;

  /* Hide the browser's default radio button */
  input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;

    /* When the radio button is checked, add a black background */
    &:checked ~ .radio {
      background-color: get-color(primary);

      /* Create the indicator (the dot/circle - hidden when not checked) */
      &::after {
        content: "";
        display: block;
        position: absolute;
      }
    }
  }

  /* Create a custom radio button */
  .radio {
    background-color: #eee;
    border-radius: 50%;
    height: 25px;
    left: 0;
    position: absolute;
    top: 0;
    width: 25px;

    /* Style the indicator (dot/circle) */
    &::after {
      background: $white;
      border-radius: 50%;
      content: "";
      display: none;
      height: 11px;
      left: 7px;
      position: absolute;
      top: 7px;
      width: 11px;
    }
  }
}
